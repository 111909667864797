import { css } from '@emotion/core';
import { Link } from 'gatsby';
import React from 'react';
import COLORS from '../styles/colors';

const styles = css`
	display: block;
	color: ${COLORS.primary};
	text-decoration: none;
	font-size: 18px;
	line-height: ${30 / 18};
	padding: 10px 0;
	border-bottom: 1px solid ${COLORS.grayLight};
	transition: border-bottom-color .2s ease-out;
	padding-right: 30px;
	position: relative;
	&::after {
		content: '→';
		position: absolute;
		top: 10px;
		right: 0;
		opacity: 0;
		transition: opacity .2s ease-out;
	}
	&:hover {
		border-bottom-color: ${COLORS.primary};
		color: ${COLORS.primary};
		&::after {
			opacity: 1;
		}
	}
`;

const LinkItem = ({ to, children }) => (
	<Link to={to} css={styles}>
		{children}
	</Link>
);

export default LinkItem;
