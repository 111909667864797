import { css } from '@emotion/core';
import React from 'react';
import { smUp, mdUp } from '../styles/breakpoints';

const styles = css`
	margin: 50px 0;
	.wrapper {
		margin-bottom: 0;
		> * {
			margin-bottom: 20px;
		}
	}
	@media ${smUp} {
		.wrapper {
			display: flex;
			margin-left: -20px;
			> * {
				flex: 1 1 auto;
				margin-left: 20px;
			}
		}
	}
	@media ${mdUp} {
		margin-left: -80px;
		margin-right: -80px;
	}
`;

const ImageBleed = ({ children }) => (
	<div css={styles}>
		<div className="wrapper">
			{children}
		</div>
	</div>
);

export default ImageBleed;
