import React from 'react';
import { css } from '@emotion/core';
import COLORS from '../styles/colors';

const styles = css`
	text-transform: uppercase;
	font-weight: bold;
	font-size: 15px;
	line-height: ${28 / 15};
	color: ${COLORS.black};
	margin-bottom: 50px;
`;

const TitleMeta = ({ children }) => (
	<p css={styles}>
		{children}
	</p>
);

export default TitleMeta;
