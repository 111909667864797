import React from 'react';
import Fade from '../components/Fade';
import HeroImage from '../components/HeroImage';
import Image from '../components/Image';
import ImageBleed from '../components/ImageBleed';
import Container from '../components/layout/Container';
import LinkItem from '../components/LinkItem';
import LinksList from '../components/LinksList';
import ScrollTo from '../components/ScrollTo';
import Meta from '../components/seo/Meta';
import SrOnlyTitle from '../components/seo/SrOnlyTitle';
import TextContent from '../components/TextContent';
import TitleMeta from '../components/TitleMeta';
import MainLayout from '../layouts/MainLayout';


const Index = () => (
	<MainLayout>
		<Meta
			title="Kdo jsem"
		/>

		<SrOnlyTitle>Kdo jsem</SrOnlyTitle>

		<HeroImage imageSrc="hero-kdo-jsem.jpg">
			<ScrollTo to="content" />
		</HeroImage>

		<Container id="content">
			<TextContent>
				<TitleMeta>Interview, květen 2019</TitleMeta>

				<Fade bottom>
					<h2 className="h3">
						Kdy jste se rozhodl stát advokátem? Bylo to přímočaré rozhodnutí nebo jste se k tomu dopracoval postupně?
					</h2>
					<p>
						Původně jsem chtěl být stavební inženýr, nahrhovat mosty, kreslit domy. Dědeček je totiž architekt, tak jsem k tomuto směru měl blízko. Znalost práva mi přišla užitečná a univerzální, myslel jsem si a stále si myslím, že znát právo je základ pro to, aby člověk mohl později dělat cokoliv. Také jsem viděl jako výhodu pro rodinné podnikání, aby aspoň jeden z případné nastupující generace znal právo. Když jsem se hlásil na vysoké školy, tak v Brně jsou obě fakulty, jak stavební tak právnická, naproti sobě přes cestu. Podal jsem přihlášky na obě školy a na obě jsem se dostal.
					</p>
				</Fade>

				<Fade bottom>
					<h2 className="h3">
						Studoval jste pak na obou současně?
					</h2>
					<p>
						Zvítězila práva. Řekl jsem si, že na stavební fakultu můžu nastoupit kdykoliv později. Postupně jsem při studiu zjišťoval, že mě právo čím dál víc baví a nejsem v něm vůbec špatný a zabralo mi všechen čas.
					</p>
				</Fade>

				<Fade bottom>
					<h2 className="h3">
						Vystupujete teď jako samostatný advokát. Práce ve větší advokátní kanceláři Vám nevyhovuje?
					</h2>
					<p>
						Doporučuji všem koncipientům začít ve velké kanceláři. Naučil jsem se tam řešit problémy, postupovat metodicky, poznal jsem i širší souvislosti. Pro mě ale bylo vždy důležité, abych mohl dělat svou práci po svém, potřebuji mít nepřistřižená křídla. Mám rád svobodu ve vlastním rozhodování. Praxe pod vlastním jménem tak byla logickým krokem.
					</p>
				</Fade>

				<ImageBleed>
					<div style={{ flexBasis: '380px' }}>
						<Fade left>
							<Image imageSrc="kdo-jsem-01.jpg" />
						</Fade>
					</div>

					<div style={{ flexBasis: '540px' }}>
						<Fade right>
							<Image imageSrc="kdo-jsem-02.jpg" />
						</Fade>
					</div>
				</ImageBleed>

				<Fade bottom>
					<h2 className="h3">
						Jak vypadá Váš běžný den v práci?
					</h2>
					<p>
						Denní náplní mé práce je psát – a psát. A odpovídat na e-maily, telefony. Zhruba jednou měsíčně navštívím soud.
					</p>
				</Fade>

				<Fade bottom>
					<h2 className="h3">
						To nezní úplně zábavně.
					</h2>
					<p>
						Pro laika je to možná dost nepochopitelná a nudná práce. Pro mě je to ale hra se slovy a práce s jazykem. Vnímám to i tak a doufám, že to tak vnímají i mí klienti, že vzniká něco hodnotného a potřebného. Uspokojením je pro mě pak třeba to, že klientovi dobře dopadne obchod.
					</p>
				</Fade>

				<Fade bottom>
					<h2 className="h3">
						Lidé mají často různé obavy oslovit se svým problémem advokáta. Jak u Vás probíhá první kontakt s klientem? Na co se má připravit?
					</h2>
					<p>
						V zásadě první kontakt může být e-mailový, který bude obsahovat souhrn toho, co klient potřebuje. Po posouzení, zda jsem vhodný k řešení danému problému a vyřešení hlavní otázky, jestli bych se nedostal do konfliktu zájmů současného a potenciálně budoucího klienta, jsem schopen upřesnit další rozsah práce, popřípadě nastínit případný další postup a jeho náročnost. Na to musí navázat osobní schůzka, kde provedu nezbytnou identifikaci klienta a můžeme celý problém rozebrat detailně.
					</p>
					<p>
						Důležité je uvědomit si, že potenciální klient nedostane odpověď ihned. Musím zodpovědně pomáhat současným klientům a následně se zamyslet nad všemi okolnostmi případu a sám jej pochopit, abych dal potenciálnímu klientovi co nejlepší radu. Připravit by se měl klient hlavně na to, že potřebuji opravdu slyšet všechno a že lhát svému advokátovi a něco mu neříct je pro důvěrný vztah advokát - klient to nejhorší.
					</p>
				</Fade>

				<Fade bottom>
					<h2 className="h3">
						A stává se, že klient neřekne všechno?
					</h2>
					<p>
						Ano, občas se to stane. Často ale neúmyslně nebo někdy ze studu. Je to jako u lékaře, kde také není pacientovi vždy příjemné sdělit vše. Ale čím víc lékař ví, tím lépe může léčit. Stejně tak advokát. Abych se pokusil předejít všem problémům a dát užitečnou radu, musím znát celý kontext problému klienta. Stejně jako lékařství i advokacie má svoji etiku a povinnosti a informace mých klientů jsou opravdu důvěrné.
					</p>
				</Fade>

				<Fade bottom>
					<h2 className="h3">
						Přejděme na Vaši specializaci - rodinné firmy. Není to trochu široká specializace?
					</h2>
					<p>
						Je to specializace spíše přístupem ke klientům, než co do zaměření jedním směrem. Rodinné firmy historicky byly, jsou a doufám nadále budou základem prosperity státu. Dá se říct, že většina společností založené po roce 1989 jsou v České republice rodinné. V současné době dochází k mezigeneračnímu předávání, což se ve většině případů nestane ze dne na den a ani příliš hladce. Díky mé rodině mám zkušenosti s rodinným podnikáním z první ruky, kdy první generace rodinnou společnost budovala, ta druhá a další ho musí dobře spravovat. Mezigenerační názorové střety a předávání otěží přináší někdy i osobní problémy, narušují se vztahy v rámci rodiny. V tomto prostředí je nutné být jak znalý práva, aby za všech okolností byla ochráněna společnost před rodinnými spory, tak také schopnosti mediátora a často i psychologa, aby se kvůli společnosti nerozštěpila rodina.
					</p>
				</Fade>

				<ImageBleed>
					<Fade bottom>
						<Image imageSrc="kdo-jsem-03.jpg" />
					</Fade>
				</ImageBleed>

				<Fade>
					<blockquote>
						<p>
							Mám asi to štěstí, že všichni moji současní klienti jsou vlastně skvělí. Každý klient je v&nbsp;podstatě v&nbsp;něčem výjimečný.
						</p>
					</blockquote>
				</Fade>

				<Fade bottom>
					<h2 className="h3">
						Co můžete rodinným firmám nabídnout za služby?
					</h2>
					<p>
						V zásadě plnohodnotný právní servis se zaměřením na obchodní právo. Zajímá mě také IT a autorské právo. Trestní nebo rodinné právo (rozvody, péče o děti) primárně neřeším. U rodinných společností se často přelévá práce i do osobních vztahů a obráceně, kde je nezbytné v obou rovinách připravovat dlouhodobé plány, které počítají i s předáváním majetku dalším generacím a působit jako mediátor.
					</p>
					<p>
						Pokud však můj klient bude potřebovat něco mimo moji specializaci, vím koho ze svých kolegů mohu s čistým svědomím doporučit.
					</p>
				</Fade>

				<Fade bottom>
					<h2 className="h3">
						Zmínil jste se o IT právu, jaké služby nabízíte IT firmám?
					</h2>
					<p>
						Nejraději se věnuji smlouvám o dílo, popřípadě nastavením vnitřní struktury společností. Je to kreativní proces – stejně jako vzniká samotné dílo či společnost, musí vzniknout i smlouva či smlouvy. Často také připravuji třeba kupní smlouvy, všeobecné obchodní podmínky, různé pracovní smlouvy a interní dokumentace.
					</p>
				</Fade>

				<Fade bottom>
					<h2 className="h3">
						Starat se o rodinnou firmu je asi dlouhodobý závazek. Řešíte také jednorázové případy?
					</h2>
					<p>
						U svých klientů preferuji dlouhodobou spolupráci, byť třeba může být v některých fázích i nárazová. Každé podnikání má svá specifika, která je potřeba pochopit, stejně jako samotného klienta. O některé klienty se starám od začátku své praxe. Samozřejmě jsem schopen zpracovat i jednorázové úkoly, jako je třeba smlouva na koupi nemovitosti. Jsou to zdánlivě jednoduché věci, které jsou dnes bohužel velmi podceňované.
					</p>
				</Fade>

				<ImageBleed>
					<div style={{ flexBasis: '540px' }}>
						<Fade left>
							<Image imageSrc="kdo-jsem-04.jpg" />
						</Fade>
					</div>

					<div style={{ flexBasis: '380px' }}>
						<Fade right>
							<Image imageSrc="kdo-jsem-05.jpg" />
						</Fade>
					</div>
				</ImageBleed>

				<Fade bottom>
					<h2 className="h3">
						Jsou ve Vaší advokátní praxi nějaké momenty, na které jste hrdý?
					</h2>
					<p>
						Opravdu hrdý jsem na to, že jsme naši rodinnou společnost Prabos plus a.s. dostali na Pražskou burzu. Je to něco, s čím se asi běžně samostatný advokát nesetká. Díky spolupráci s dalšími ekonomickými a právními poradci jsem měl možnost se toho všeho zúčastnit. Byla to zatím jedinečná zkušenost.
					</p>
				</Fade>

				<Fade bottom>
					<h2 className="h3">
						Máte nějaký vysněný projekt nebo klienta?
					</h2>
					<p>
						Mám asi to štěstí, že všichni moji současní klienti jsou vlastně skvělí. Každý klient je v podstatě v něčem výjimečný. Baví mě s nimi sdílet jejich úspěchy, překonávat překážky a postupně pomáhat v jejich rozvoji a růstu. Nezáleží mi tolik na tom, zda je klient velký, malý nebo mediálně známý, důležitá je důvěra klienta ve mně a obráceně. 
					</p>
				</Fade>

				<Fade bottom>
					<h2 className="h3">
						Co byste dnes dělal jiného, kdybyste nevystudoval práva?
					</h2>
					<p>
						Patrně bych byl stavební inženýr a vedl svůj ateliér. Popravdě si už ale těžko představuji, že bych dělal něco jiného.
					</p>
				</Fade>

				<Fade bottom>
					<h2 className="h3">
						Jak relaxujete, když si potřebujete odpočinout od práce?
					</h2>
					<p>
						Jdu si zaběhat na čerstvý vzduch nebo jdu na golf. Kvůli práci hodně čtu, ve volném čase proto rád poslouchám audioknihy. Obecně dělám cokoliv, u čeho můžu bez obav vypnout a odstřihnout se od všech pracovních myšlenek.
					</p>
				</Fade>

				<Fade bottom>
					<h2 className="h3">
						Jaké atributy nebo hodnoty by podle Vás měl zastávat dnešní advokát?
					</h2>
					<p>
						Problém i krása advokacie je v tom, že jsou v ní ti nejlepší i ti nejhorší. Ti nejhorší bohužel kazí reputaci celému stavu. V zásadě by však advokát měl být seriózní, poctivý a spolehlivý. Advokát by neměl svoji práci brát příliš osobně. Musí mít nad celým problémem nadhled. Mnohdy advokát funguje částečně i jako psycholog. A můj názor je takový, že by klient ani neměl o svém advokátovi vědět, resp. není nutné ho mít na očích nebo denně na telefonu, ale je důležité, aby byly vidět jeho výsledky a dalo se na něho spolehnout, popřípadě aby byl k dispozici tehdy, kdy je to potřeba.
					</p>
				</Fade>

				<ImageBleed>
					<Fade bottom>
						<Image imageSrc="kdo-jsem-06.jpg" />
					</Fade>
				</ImageBleed>

				<Fade bottom>
					<h2 className="h3">Potřebujete vědět více?</h2>
				</Fade>
				<LinksList>
					<LinkItem to="/me-advokatni-sluzby/">Podívejte se, jaké služby vám mohu nabídnout.</LinkItem>
					{/* <LinkItem to="/blog/">Píšu o svých zkušenostech a důležitých věcech.</LinkItem> */}
					<LinkItem to="/kontakty/">Jak se můžeme spojit?</LinkItem>
				</LinksList>
			</TextContent>
		</Container>
	</MainLayout>
);

Index.propTypes = {};

export default Index;
