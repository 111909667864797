import React from 'react';
import { css } from '@emotion/core';
import { smUp } from '../styles/breakpoints';

const styles = css`
	max-width: 780px;
	margin: 0 auto;
	@media ${smUp} {
		margin-top: -50px;
	}
`;

const TextContent = ({ children }) => (
	<div css={styles}>
		{children}
	</div>
);

export default TextContent;
