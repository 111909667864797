import { css } from '@emotion/core';
import { graphql, StaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';

const styles = css`
`;

const HeroImage = ({
	children, data, imageSrc, style,
}) => (
	<figure css={styles} style={style}>
		<StaticQuery
			query={graphql`
						query {
							allImageSharp {
								edges {
									node {
										fluid(maxWidth: 1920, quality: 100) {
											...GatsbyImageSharpFluid_withWebp
										}
									}
								}
							}
						}
					`}
			render={data => (
				<Img fluid={data.allImageSharp.edges.find(
					image => image.node.fluid.src.split('/').pop() === imageSrc,
				).node.fluid}
				/>
			)}
		/>
	</figure>
);

export default HeroImage;
